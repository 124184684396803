<template>
  <div>
    <div v-if="!contratos.length && !loading">
      <CAlert color="danger" class="text-center mt-2">
        Nenhum contrato localizado
      </CAlert>
    </div>
    <div v-else class="row mt-2">
      <div class="col-4" v-for="contrato in contratos">
        <CCard>
          <CCardBody>
            <CCardTitle>
              <small>#{{ contrato.id }}</small> <br>
              {{ contrato.nome }}
            </CCardTitle>
            <CCardSubtitle>
              <strong class="text-success">
                {{ contrato.valor | monetary }}
              </strong>
            </CCardSubtitle>
            <CCardText>
              <table class="table table-hover table-clear table-sm my-1">
                <tr>
                  <td class="pl-0"><strong>Início:</strong></td>
                  <td class="pr-0 text-right">{{ contrato.inicio | date }}</td>
                </tr>
                <tr>
                  <td class="pl-0"><strong>Próx. Renovação:</strong></td>
                  <td class="pr-0 text-right">{{ contrato.proximaRenovacao | date }}</td>
                </tr>
              </table>
              <CButton color="primary" :disabled="!contrato.pacotes.length" @click="onClick(contrato)">
                <span v-if="!!contrato.pacotes.length">
                  Ver {{ contrato.pacotes.length }} pacote(s)
                </span>
                <span v-else>
                  Nenhum pacote
                </span>
              </CButton>
            </CCardText>
          </CCardBody>
        </CCard>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListaContratos",
  props: [
    'loading',
    'contratos',
    'onClick'
  ],
}
</script>