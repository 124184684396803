<template>
  <div>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Contratos">
        <ListaContratos
          v-if="!contratoSelecionado"
          :loading="loading"
          :contratos="contratos"
          :onClick="abrirPacotes"
        />

        <ListaPacotes
          v-if="!!contratoSelecionado"
          :contrato="contratoSelecionado"
          :onCloseContrato="fecharPacotes"
        />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import { get } from "../../helpers/apiRequest";
import ListaContratos from "./ListaContratos";
import ListaPacotes from "./ListaPacotes";

export default {
  name: "contratos-licencas",
  components: {
    ListaContratos,
    ListaPacotes
  },
  data() {
    return {
      loading: false,
      contratos: [],
      contratoSelecionado: null,
    };
  },
  methods: {
    loadContratos() {
      this.loading = true;
      get("/admin/licencas")
          .then((json) => {
            this.contratos = json;
          })
          .catch((err) => {})
          .finally(() => this.loading = false)
    },
    abrirPacotes(contrato) {
      this.contratoSelecionado = contrato;
    },
    fecharPacotes() {
      this.contratoSelecionado = null;
    }
  },
  beforeMount() {
    this.loadContratos();
  },
};
</script>
