<template>
  <CCard>
    <CCardHeader>
      <CCardTitle class="m-0">
        <div class="d-flex justify-content-between">
          <div>
            <small>#{{ contrato.id }}</small> <br>
            {{ contrato.nome }}
          </div>
          <div class="font-sm">
            <table class="table table-hover table-clear table-sm m-0">
              <tr>
                <td class="pl-0"><strong>Início:</strong></td>
                <td class="pr-0 text-right">{{ contrato.inicio | date }}</td>
              </tr>
              <tr>
                <td class="pl-0"><strong>Próx. Renovação:</strong></td>
                <td class="pr-0 text-right">{{ contrato.proximaRenovacao | date }}</td>
              </tr>
            </table>
          </div>
          <div class="text-center">
            <CButton class="btn btn-sm btn-primary" @click="onCloseContrato">
              &larr; Fechar e voltar
            </CButton>
            <br>
            <strong class="text-success font-sm">
              {{ contrato.valor | monetary }}
            </strong>
          </div>
        </div>
      </CCardTitle>
    </CCardHeader>
    <table class="table m-0">
      <thead>
        <tr>
          <th class="col-2">Cód</th>
          <th class="col-2">Pacote</th>
          <th class="col-2 text-right">Valor</th>
          <th class="col-2 text-center">Licenças</th>
          <th class="col-2 text-center">Acesso</th>
          <th class="col-2 text-right">#</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="pacote in contrato.pacotes">
          <tr :class="pacoteSelecionado === pacote ? 'table-active': null">
            <td>#{{ pacote.id }}</td>
            <td>{{ pacote.nome }}</td>
            <td class="text-right">{{ pacote.valor | monetary }}</td>
            <td class="center">
              <span v-if="pacote.ilimitado" class="text-success">
                <strong>ILIMITADO</strong>
              </span>
                <span v-else>
                <strong :class="pacote.licencas_sem_uso > 0 ? 'text-success' : 'text-danger'">
                  {{ pacote.licencas_em_uso }} /
                  {{ pacote.numero_licencas }}
                </strong>
              </span>
            </td>
            <td>
              {{ pacote.tipo_acesso.nome_exibicao }}
              <br>
              <span v-for="sistema in pacote.sistemas" class="badge badge-light mr-1">
                {{ sistema.nome_exibicao }}
              </span>
            </td>
            <td class="text-right">
              <CButton
                v-if="pacoteSelecionado !== pacote"
                class="btn btn-sm btn-link"
                :disabled="pacote.licencas_em_uso <= 0 || pacote.ilimitado"
                @click="verUsuarios(pacote)"
              >
                Ver usuários
              </CButton>
              <CButton
                  v-else
                  class="btn btn-sm btn-link"
                  @click="fecharVisualizacaoUsuarios"
              >
                Fechar visualização
              </CButton>
            </td>
          </tr>
          <tr v-if="pacoteSelecionado === pacote" class="table-active">
            <td colspan="6">
              <div class="bg-white text-dark">
                <table class="table table-hover table-sm m-0">
                  <thead>
                    <tr>
                      <th colspan="5">
                        <input type="text" class="form-control" v-model="termo" placeholder="Pesquisar colaborador ..."/>
                      </th>
                    </tr>
                    <tr>
                      <th class="col-2">#Cód Colab.</th>
                      <th class="col-3">Colaborador</th>
                      <th class="col-3">Tipo</th>
                      <th class="col-2">Cód.</th>
                      <th class="col-2 text-right">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="colaborador in colaboradoresEmUso">
                      <td>{{ colaborador.id  }}</td>
                      <td>
                        <strong>{{ colaborador.nome  }}</strong>
                        <br>
                        <small>{{ colaborador.email }}</small>
                      </td>
                      <td>
                        {{ colaborador.tipoDescricao  }} <br>
                        <small>{{ colaborador.tipoChave }}</small>
                      </td>
                      <td>{{ colaborador.codTipo }}</td>
                      <td class="text-right">
                        <CButton color="danger" size="sm" @click="desvincularLicencaColaborador(colaborador)">
                          <fa-icon icon="trash-alt" />
                        </CButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </CCard>
</template>

<script>
import CloseModal from "../../components/CloseModal";
import _reduce from "lodash/reduce";
import _findIndex from "lodash/findIndex";
import {del} from "../../helpers/apiRequest";
import {  arrayFilter } from '@/helpers/utils';

export default {
  name: "ListaPacotes",
  components: {
    CloseModal,
  },
  props: [
    'contrato',
    'onCloseContrato'
  ],
  data() {
    return {
      termo: '',
      pacoteSelecionado: null
    }
  },
  computed: {
    colaboradoresEmUso() {
      let _colaboradoresEmUso = _reduce(
        this.pacoteSelecionado.licencas,
        (agg, licenca) => {
          if (licenca.colaborador !== null) {
            licenca.colaborador.token = licenca.token;
            agg.push(licenca.colaborador);
          }
          return agg;
        },
        []
      );

      if (this.termo !== '') {
        return arrayFilter(_colaboradoresEmUso, this.termo, ['nome', 'email', 'codTipo', 'id'])
      }

      return _colaboradoresEmUso;
    },
  },
  methods: {
    verUsuarios(pacote) {
      this.termo = '';
      this.pacoteSelecionado = pacote;
    },
    fecharVisualizacaoUsuarios() {
      this.pacoteSelecionado = null;
    },
    desvincularLicencaColaborador(colaborador) {
      this.$swal({
        title: 'Confirma desvincular licença?',
        text: 'Esta ação irá desvincular a licença associada ao usuário, que poderá ser reutilizada através do menu Cadastros > Colaboradores',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Desvincular',
        cancelButtonText: 'Cancelar',
      })
      .then((res) => {
        if (!res.isConfirmed) {
          return;
        }
        del(`/admin/licencas/${colaborador.token}`)
          .then(() => {
            const index = _findIndex(this.pacoteSelecionado.licencas, { token: colaborador.token });

            if (index >= 0) {
              this.pacoteSelecionado.licencas[index].colaborador = null;
              this.pacoteSelecionado.licencas_em_uso -= 1;
              this.pacoteSelecionado.licencas_sem_uso += 1;
            }

            if (this.pacoteSelecionado.licencas_em_uso <= 0) {
              this.fecharVisualizacaoUsuarios();
            }
          })
          .catch(() => {})
      });
    }
  }
}
</script>